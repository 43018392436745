import React, { useEffect } from 'react';
import styled from 'styled-components';

// Layouts
import HeaderLegal from './Layouts/header-legal';
import Footer from './Layouts/footer';

// Modal Video 
// import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import '../css/pe-icon-7.css';
import '../css/materialdesignicons.min.css';

import 'react-image-lightbox/style.css';

const RequestsText = () => {
  return (
    <>
      <p><span>Effective date: August 19, 2020</span></p>
      <p><span>Are you a law enforcement officer conducting an investigation that may involve user content hosted on Khyros? Or maybe you're a privacy-conscious person who would like to know what information we share with law enforcement and under what circumstances. Either way, you're on the right page.</span></p>
      <p><span>In these guidelines, we provide a little background about what Khyros is, the types of data we have, and the conditions under which we will disclose private user information. Before we get into the details, however, here are a few important details you may want to know:</span></p>
      <ul>
        <li><span>We will notify affected users about any requests for their account information, unless prohibited from doing so by law or court order.</span></li>
        <li><span>We will not disclose location-tracking data, such as IP address logs, without a valid court order or search warrant.</span></li>
        <li><span>We will not disclose any private user content without a valid search warrant.</span></li>
      </ul>
      

      <h3><span>About These Guidelines</span></h3>
      <p><span>Our users trust us with their data—often some of their most valuable business or personal assets. Maintaining that trust is essential to us, which means keeping user data safe, secure, and private.</span></p>
      <p><span>While the overwhelming majority of our users use Khyros's services to create new businesses, build new technologies, and for the general betterment of humankind, we recognize there are bound to be a few bad apples in the bunch. In those cases, we want to help law enforcement serve their legitimate interest in protecting the public.</span></p>
      <p><span>By providing guidelines for law enforcement personnel, we hope to strike a balance between the often competing interests of user privacy and justice. We hope these guidelines will help to set expectations on both sides, as well as to add transparency to Khyros's internal processes. Our users should know that we value their private information and that we do what we can to protect it. At a minimum, this means only releasing data to third-parties when the appropriate legal requirements have been satisfied. By the same token, we also hope to educate law enforcement professionals about Khyros's systems so that they can more efficiently tailor their data requests and target just that information needed to conduct their investigation.</span></p>


      <h3><span>We Will Notify Any Affected Account Owners</span></h3>
      <p><span>It is our policy to notify users about any pending requests regarding their accounts, unless we are prohibited by law or court order from doing so. Before disclosing user information, we will make a reasonable effort to notify any affected account owner(s) by sending a message to their verified email address providing them with a copy of the subpoena, court order, or warrant so that they can have an opportunity to challenge the legal process if they wish. In (rare) exigent circumstances, we may delay notification if we determine delay is necessary to prevent death or serious harm.</span></p>
      
      
      <h3><span>Disclosure of Non-Public Information</span></h3>
      <p><span>It is our policy to disclose non-public user information in connection with a civil or criminal investigation only with user consent or upon receipt of a valid subpoena, civil investigative demand, court order, search warrant, or other similar valid legal process. In certain exigent circumstances (see below), we also may share limited information but only corresponding to the nature of the circumstances, and would require legal process for anything beyond that. Khyros reserves the right to object to any requests for non-public information. Where Khyros agrees to produce non-public information in response to a lawful request, we will conduct a reasonable search for the requested information. Here are the kinds of information we will agree to produce, depending on the kind of legal process we are served with:</span></p>
      <ul>
        <li><span>With user consent — Khyros will provide private account information, if requested, directly to the user (or an owner, in the case of an organization account), or to a designated third party with the user's written consent once Khyros is satisfied that the user has verified his or her identity.</span></li>
        <li><span>With a subpoena — If served with a valid subpoena, civil investigative demand, or similar legal process issued in connection with an official criminal or civil investigation, we can provide certain non-public account information, which may include:</span></li>
        <ul>
          <li><span>Name(s) associated with the account</span></li>
          <li><span>Email address(es) associated with the account</span></li>
          <li><span>Billing information</span></li>
          <li><span>Registration date and termination date</span></li>
          <li><span>IP address, date, and time at the time of account registration</span></li>
          <li><span>IP address(es) used to access the account at a specified time or event relevant to the investigation</span></li>
        </ul>
      </ul>
      
      <p><span>In the case of organization accounts, we can provide the name(s) and email address(es) of the account owner(s) as well as the date and IP address at the time of creation of the organization account. We will not produce information about other members or contributors, if any, to the organization account or any additional information regarding the identified account owner(s) without a follow-up request for those specific users.</span></p>
      <p><span>Please note that the information available will vary from case to case. Some of the information is optional for users to provide. In other cases, we may not have collected or retained the information.</span></p>
      <ul>
        <li><span>With a court order or a search warrant — We will not disclose account access logs unless compelled to do so by either (i) a court order issued under 18 U.S.C. Section 2703(d), upon a showing of specific and articulable facts showing that there are reasonable grounds to believe that the information sought is relevant and material to an ongoing criminal investigation; or (ii) a search warrant issued under the procedures described in the Federal Rules of Criminal Procedure or equivalent state warrant procedures, upon a showing of probable cause. In addition to the non-public user account information listed above, we can provide account access logs in response to a court order or search warrant, which may include:</span></li>
        <ul>
          <li><span>Any logs which would reveal a user's movements over a period of time</span></li>
          <li><span>Account (for example, which users have certain permissions, etc.)</span></li>
          <li><span>User- or IP-specific analytic data</span></li>
          <li><span>Security access logs other than account creation or for a specific time and date</span></li>
        </ul>
        <li><span>Only with a search warrant — We will not disclose the private contents of any user account unless compelled to do so under a search warrant issued under the procedures described in the Federal Rules of Criminal Procedure or equivalent state warrant procedures upon a showing of probable cause. In addition to the non-public user account information and account access logs mentioned above, we will also provide private user account contents in response to a search warrant, which may include any security keys used for authentication or encryption</span></li>
      </ul>

      <p><span>Under exigent circumstances — If we receive a request for information under certain exigent circumstances (where we believe the disclosure is necessary to prevent an emergency involving danger of death or serious physical injury to a person), we may disclose limited information that we determine necessary to enable law enforcement to address the emergency. For any information beyond that, we would require a subpoena, search warrant, or court order, as described above. Before disclosing information, we confirm that the request came from a law enforcement agency, an authority sent an official notice summarizing the emergency, and how the information requested will assist in addressing the emergency.</span></p>

      
      <h3><span>Cost Reimbursement</span></h3>
      <p><span>We reserve the right to seek reimbursement for administrative costs associated with responding to requests for information, as allowed by law.</span></p>
      
      
      <h3><span>Data Preservation</span></h3>
      <p><span>We will take steps to preserve account records for up to 90 days upon formal request from U.S. law enforcement in connection with official criminal investigations, and pending the issuance of a court order or other process.</span></p>


      <h3><span>Submitting Requests</span></h3>
      <p><span>Please serve requests to our website contact form.</span></p>
      <p><span>Please make your requests as specific and narrow as possible, including the following information:</span></p>
      <ul>
        <li><span>Full information about authority issuing the request for information</span></li>
        <li><span>The name and badge/ID of the responsible agent</span></li>
        <li><span>An official email address and contact phone number</span></li>
        <li><span>The user, organization, repository name(s) of interest</span></li>
        <li><span>The URLs of any pages, gists or files of interest</span></li>
        <li><span>The description of the types of records you need</span></li>
      </ul>
      <p><span>Please allow at least two weeks for us to be able to look into your request.</span></p>


      <h3><span>Requests from Foreign Law Enforcement</span></h3>
      <p><span>As a United States company, Khyros is not required to provide data to foreign governments in response to legal process issued by foreign authorities. Foreign law enforcement officials wishing to request information from Khyros should contact the United States Department of Justice Criminal Division's Office of International Affairs. </span></p>

      <h3><span>Questions</span></h3>
      <p><span>Do you have other questions, comments or suggestions? Please contact Khyros.</span></p>
    </>
  );
}


const TermsContentHeaderSection = styled.section`
  margin-top:60px;
`;

const RequestsContent = () => {
  return (
    <>
    <TermsContentHeaderSection className="section bg-light" id="privacy"> 
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title text-center">
              <h1>Guidelines for Legal Requests of User Data</h1>
            </div>
          </div>
        </div>
      </div>
    </TermsContentHeaderSection>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <RequestsText />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const Requests = () => {
  /*
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // @ts-ignore
      document.getElementById('nav-bar').classList.add('nav-sticky');
    }
    else {
      // @ts-ignore
      document.getElementById('nav-bar').classList.remove('nav-sticky');
    }
  }
  */

  useEffect(() => {
    // @ts-ignore
    // document.body.classList = "";
    // window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  return (
    <React.Fragment>
      <HeaderLegal noScrollSpy={false} light={true} />
      <RequestsContent />
      <Footer />
    </React.Fragment >
  );

}

export default Requests;