import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Layouts
import HeaderLegal from './Layouts/header-legal';
import Footer from './Layouts/footer';

// Modal Video 
// import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import '../css/pe-icon-7.css';
import '../css/materialdesignicons.min.css';

import 'react-image-lightbox/style.css';


const TermsText = () => {
  return (
    <>
      <p><span>Effective date: June 10, 2020</span></p>
      <h3><span>A. Definitions</span></h3>
      <p><span>Short version: We use these basic terms throughout the agreement, and they have specific meanings. You should know what we mean when we use each of the terms. There's not going to be a test on it, but it's still useful information.</span></p>
      <ol>
        <li><span>An "Account" represents your legal relationship with Khyros. A “User Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity on Khyros. “Organizations” are shared workspaces that may be associated with a single entity or with one or more Users where multiple Users can collaborate across many projects at once. A User Account can be a member of any number of Organizations.</span></li>
        <li><span>The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the "Terms") and all other operating rules, policies and procedures that we may publish from time to time on the Website. </span></li>
        <li><span>"Beta Previews" mean software, services, or features identified as alpha, beta, preview, early access, or evaluation, or words or phrases with similar meanings.</span></li>
        <li><span>“Content” refers to content featured or displayed through the Website, including without limitation code, text, data, articles, images, photographs, graphics, software, applications, packages, designs, features, and other materials that are available on the Website or otherwise available through the Service. "Content" also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. "Your Content" is Content that you create or own.</span></li>
        <li><span>“Khyros,” “We,” and “Us” refer to Khyros, Inc., as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.</span></li>
        <li><span>The “Service” refers to the applications, software, products, and services provided by Khyros, including any Beta Previews.</span></li>
        <li><span>“The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age. Special terms may apply for business or government Accounts (See Section B(5): Additional Terms).</span></li>
        <li><span>The “Website” refers to Khyros’s website located at </span><span><Link to="/Home" target="_top">khyros.ai</Link></span><span>, and all content, services, and products provided by Khyros at or through the Website.</span></li>
      </ol>
      

      <h3><span>B. Account Terms</span></h3>
      <p><span>Short version: User Accounts and Organizations have different administrative controls; a human must create your Account; you must be 13 or over; you must provide a valid email address; and you may not have more than one free Account. You alone are responsible for your Account and anything that happens while you are signed in to or using your Account. You are responsible for keeping your Account secure.</span></p>

      <h4><span>1. Account Controls</span></h4>
      <ul>
        <li><span>Users. Subject to these Terms, you retain ultimate administrative control over your User Account and the Content within it.</span></li>
        <li><span>Organizations. The "owner" of an Organization that was created under these Terms has ultimate administrative control over that Organization and the Content within it. Within the Service, an owner can manage User access to the Organization’s data and projects. An Organization may have multiple owners, but there must be at least one User Account designated as an owner of an Organization. If you are the owner of an Organization under these Terms, we consider you responsible for the actions that are performed on or through that Organization.</span></li>
      </ul>

      <h4><span>2. Required Information</span></h4>
      <p><span>You must provide a valid email address in order to complete the signup process. Any other information requested, such as your real name, is optional, unless you are accepting these terms on behalf of a legal entity (in which case we need more information about the legal entity) or if you opt for a paid Account, in which case additional information will be necessary for billing purposes.</span></p>

      <h4><span>3. Account Requirements</span></h4>
      <p><span>We have a few simple rules for User Accounts on Khyros's Service.</span></p>
      <ul>
        <li><span>You must be a human to create an Account. Accounts registered by "bots" or other automated methods are not permitted. We do permit machine accounts.</span></li>
        <li><span>A machine account is an Account set up by an individual human who accepts the Terms on behalf of the Account, provides a valid email address, and is responsible for its actions. A machine account is used exclusively for performing automated tasks. Multiple users may direct the actions of a machine account, but the owner of the Account is ultimately responsible for the machine's actions. You may maintain no more than one free machine account in addition to your free User Account.</span></li>
        <li><span>One person or legal entity may maintain no more than one free Account (if you choose to control a machine account as well, that's fine, but it can only be used for running a machine).</span></li>
        <li><span>You must be age 13 or older. While we are thrilled to see brilliant young analysts get excited by learning to work with geospatial data, we must comply with United States law. Khyros does not target our Service to children under 13, and we do not permit any Users under 13 on our Service. If we learn of any User under the age of 13, we will terminate that User’s Account immediately. If you are a resident of a country outside the United States, your country’s minimum age may be older; in such a case, you are responsible for complying with your country’s laws.</span></li>
        <li><span>Your login may only be used by one person — i.e., a single login may not be shared by multiple people. A paid Organization may only provide access to as many User Accounts as your subscription allows.</span></li>
        <li><span>You may not use Khyros in violation of export control or sanctions laws of the United States or any other applicable jurisdiction. You may not use Khyros if you are or are working on behalf of a </span><span><a href="https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx">Specially Designated National (SDN)</a></span><span> or a person subject to similar blocking or denied party prohibitions administered by a U.S. government agency. Khyros may allow persons in certain sanctioned countries or territories to access certain Khyros services pursuant to U.S. government authorizations.</span></li>
      </ul>

      <h4><span>4. User Account Security</span></h4>
      <p><span>You are responsible for keeping your Account secure while you use our Service. We offer tools such as two-factor authentication to help you maintain your Account's security, but the content of your Account and its security are up to you.</span></p>
      <ul>
        <li><span>You are responsible for all content posted and activity that occurs under your Account (even when content is posted by others who have Accounts under your Account).</span></li>
        <li><span>You are responsible for maintaining the security of your Account and password. Khyros cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</span></li>
        <li><span>You will promptly notify Khyros if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.</span></li>
      </ul>

      <h4><span>5. Additional Terms</span></h4>
      <p><span>In some situations, third parties' terms may apply to your use of Khyros. For example, you may be a member of an organization on Khyros with its own terms or license agreements; or you may download an application that integrates with Khyros. Please be aware that while these Terms are our full agreement with you, other parties' terms govern their relationships with you.</span></p>
      <p><span>If you are a government User or otherwise accessing or using any Khyros Service in a government capacity, this </span><span><Link to="/government-amendment-to-terms" target="_top">Terms & Conditions</Link></span><span> applies to you, and you agree to its provisions.</span></p>
      

      <h3><span>C. Acceptable Use</span></h3>
      <p><span>Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.</span></p>


      <h3><span>D. User-Generated Content</span></h3>
      <h4><span>1. Responsibility for User-Generated Content</span></h4>
      <p><span>You may create or upload User-Generated Content while using the Service. You are solely responsible for the content of, and for any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via the Service, regardless of the form of that Content. We are not responsible for any public display or misuse of your User-Generated Content.</span></p>

      <h4><span>2. Khyros May Remove Content</span></h4>
      <p><span>We do not pre-screen User-Generated Content, but we have the right (though not the obligation) to refuse or remove any User-Generated Content that, in our sole discretion, violates any Khyros terms or policies.</span></p>

      <h4><span>3. Ownership of Content, Right to Post, and License Grants</span></h4>
      <p><span>You retain ownership of and responsibility for Your Content. If you're posting anything you did not create yourself or do not own the rights to, you agree that you are responsible for any Content you post; that you will only submit Content that you have the right to post; and that you will fully comply with any third party licenses relating to Content you post.</span></p>
      <p><span></span>Because you retain ownership of and responsibility for Your Content, we need you to grant us — and other Khyros Users — certain legal permissions, listed in Sections D.4 — D.6. These license grants apply to Your Content. If you upload Content that already comes with a license granting Khyros the permissions we need to run our Service, no additional license is required. You understand that you will not receive any payment for any of the rights granted in Sections D.4 — D.6. The licenses you grant to us will end when you remove Your Content from our servers.</p>

      <h4><span>4. License Grant to Us</span></h4>
      <p><span>We need the legal right to do things like host Your Content, publish it, and share it. You grant us and our legal successors the right to store and parse Your Content. This includes the right to do things like copy it to our database and make backups; show it to you; parse it into a search index or otherwise analyze it on our servers; share it with other users.</span></p>
      <p><span>This license does not grant Khyros the right to sell Your Content or otherwise distribute or use it outside of our provision of the Service.</span></p>

      <h4><span>5. Moral Rights</span></h4>
      <p><span>You retain all moral rights to Your Content that you upload, publish, or submit to any part of the Service, including the rights of integrity and attribution. However, you waive these rights and agree not to assert them against us, to enable us to reasonably exercise the rights granted in Section D.4, but not otherwise.</span></p>
      <p><span>To the extent this agreement is not enforceable by applicable law, you grant Khyros the rights we need to use Your Content without attribution and to make reasonable adaptations of Your Content as necessary to render the Website and provide the Service.</span></p>


      <h3><span>E. Copyright Infringement and DMCA Policy</span></h3>
      <p><span>If you believe that content on our website violates your copyright, please contact us. If you are a copyright owner and you believe that content on Khyros violates your rights, please contact us via our website. There may be legal consequences for sending a false or frivolous takedown notice. Before sending a takedown request, you must consider legal uses such as fair use and licensed uses.</span></p>


      <h3><span>F. Intellectual Property Notice</span></h3>
      <p><span>Short version: We own the service and all of our content. In order for you to use our content, we give you certain rights to it, but you may only use our content in the way we have allowed.</span></p>

      <h4><span>1. Khyros’s Rights to Content</span></h4>
      <p><span>Khyros and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual property rights of any kind related to the Website and Service. We reserve all rights that are not expressly granted to you under this Agreement or by law. The look and feel of the Website and Service is copyright © Khyros, Inc. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, Typescript, or visual design elements or concepts without express written permission from Khyros.</span></p>

      <h4><span>2. Khyros Trademarks and Logos</span></h4>
      <p><span>If you’d like to use Khyros’s trademarks, you must follow all of our trademark guidelines.</span></p>

      <h4><span>3. License to Khyros Policies</span></h4>
      <p><span>This Agreement is licensed under this </span><span><a href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero license</a></span><span>.</span></p>


      <h3><span>G. API Terms</span></h3>
      <p><span>Short version: You agree to these Terms of Service, plus this Section H, when using any of Khyros's APIs (Application Provider Interface), including use of the API through a third party product that accesses Khyros.</span></p>
      <p><span>Abuse or excessively frequent requests to Khyros via the API may result in the temporary or permanent suspension of your Account's access to the API. Khyros, in our sole discretion, will determine abuse or excessive usage of the API. We will make a reasonable attempt to warn you via email prior to suspension.</span></p>
      <p><span>You may not share API tokens to exceed Khyros's rate limitations.</span></p>
      <p><span>You may not use the API to download data or Content from Khyros for spamming purposes, including for the purposes of selling Khyros users' personal information, such as to recruiters, headhunters, and job boards.</span></p>
      <p><span>All use of the Khyros API is subject to these Terms of Service and the Khyros Privacy Statement.</span></p>
      <p><span>Khyros offers subscription-based access to our API for those Users who require high-throughput access or access that would result in resale of Khyros's Service.</span></p>


      <h3><span>H. Khyros Additional Product Terms</span></h3>
      <p><span>Short version: You need to follow certain specific terms and conditions for Khyros's various features and products.</span></p>


      <h3><span>I. Payment</span></h3>
      <p><span>Short version: You are responsible for any fees associated with your use of Khyros. We are responsible for communicating those fees to you clearly and accurately, and letting you know well in advance if those prices change.</span></p>

      <h4><span>1. Pricings</span></h4>
      <p><span>Our pricing and payment terms are available by request. If you agree to a subscription price, that will remain your price for the duration of the payment term; however, prices are subject to change at the end of a payment term.</span></p>

      <h4><span>2. Upgrades, Downgrades, and Changes</span></h4>
      <ul>
        <li><span>We will immediately bill you when you upgrade from the free plan to any paying plan.</span></li>
        <li><span>If you change from a monthly billing plan to a yearly billing plan, Khyros will bill you for a full year at the next monthly billing date.</span></li>
        <li><span>If you upgrade to a higher level of service, we will bill you for the upgraded plan immediately.</span></li>
      </ul>

      <h4><span>3. Billing Schedule; No Refunds</span></h4>
      <p><span>Payment Based on Plan For monthly or yearly payment plans, the Service is billed in advance on a monthly or yearly basis respectively and is non-refundable. There will be no refunds or credits for partial months of service, downgrade refunds, or refunds for months unused with an open Account; however, the service will remain active for the length of the paid billing period. In order to treat everyone equally, no exceptions will be made.</span></p>
      <p><span>Payment Based on Usage Some Service features are billed based on your usage. A limited quantity of these Service features may be included in your plan for a limited term without additional charge. If you choose to purchase paid Service features beyond the quantity included in your plan, you pay for those Service features based on your actual usage in the preceding month. Monthly payment for these purchases will be charged on a periodic basis in arrears. </span></p>
      <p><span>Invoicing For invoiced Users, User agrees to pay the fees in full, up front without deduction or setoff of any kind, in U.S. Dollars. User must pay the fees within thirty (30) days of the Khyros invoice date. Amounts payable under this Agreement are non-refundable, except as otherwise provided in this Agreement. If User fails to pay any fees on time, Khyros reserves the right, in addition to taking any other action at law or equity, to (i) charge interest on past due amounts at 1.0% per month or the highest interest rate allowed by law, whichever is less, and to charge all expenses of recovery, and (ii) terminate the applicable order form. User is solely responsible for all taxes, fees, duties and governmental assessments (except for taxes based on Khyros's net income) that are imposed or become due in connection with this Agreement.</span></p>

      <h4><span>4. Authorization</span></h4>
      <p><span>By agreeing to these Terms, you are giving us permission to charge your on-file credit card, PayPal account, or other approved methods of payment for fees that you authorize for Khyros.</span></p>

      <h4><span>5. Responsibility for Payment</span></h4>
      <p><span>You are responsible for all fees, including taxes, associated with your use of the Service. By using the Service, you agree to pay Khyros any charge incurred in connection with your use of the Service. If you dispute the matter, contact Khyros. You are responsible for providing us with a valid means of payment for paid Accounts. Free Accounts are not required to provide payment information.</span></p>


      <h3><span>J. Cancellation and Termination</span></h3>
      <p><span>Short version: You may close your Account at any time. If you do, we'll treat your information responsibly.</span></p>

      <h4><span>1. Account Cancellation</span></h4>
      <p><span>It is your responsibility to properly cancel your Account with Khyros. You can cancel your Account at any time by going into your Settings in the navigation bar at the top of the screen. The Account screen provides a simple, no questions asked cancellation link. We are not able to cancel Accounts in response to an email or phone request.</span></p>

      <h4><span>2. Upon Cancellation</span></h4>
      <p><span>We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile and the Content of your repositories within 90 days of cancellation or termination (though some information may remain in encrypted backups). This information can not be recovered once your Account is cancelled.</span></p>

      <h4><span>3. Khyros May Terminate</span></h4>
      <p><span>Khyros has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. Khyros reserves the right to refuse service to anyone for any reason at any time.</span></p>

      <h4><span>4. Survival</span></h4>
      <p><span>All provisions of this Agreement which, by their nature, should survive termination will survive termination — including, without limitation: ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</span></p>


      <h3><span>K. Communications with Khyros</span></h3>
      <p><span>Short version: We use email and other electronic means to stay in touch with our users.</span></p>

      <h4><span>1. Electronic Communication Required</span></h4>
      <p><span>For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.</span></p>
      
      <h4><span>2. Legal Notice to Khyros Must Be in Writing</span></h4>
      <p><span>Communications made through email or Khyros Support's messaging system will not constitute legal notice to Khyros or any of its officers, employees, agents or representatives in any situation where notice to Khyros is required by contract or any law or regulation. Legal notice to Khyros must be in writing and served on Khyros's legal agent. For legal agent information, please contact Khyros.</span></p>
      
      <h4><span>3. No Phone Support</span></h4>
      <p><span>Khyros only offers support via email, in-Service communications, and electronic messages. We do not offer telephone support.</span></p>


      <h3><span>L. Disclaimer of Warranties</span></h3>
      <p><span>Short version: We provide our service as is, and we make no promises or guarantees about this service. Please read this section carefully; you should understand what to expect.</span></p>
      <p><span>Khyros provides the Website and the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.</span></p>
      <p><span>Khyros does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.</span></p>


      <h3><span>M. Limitation of Liability</span></h3>
      <p><span>Short version: We will not be liable for damages or losses arising from your use or inability to use the service or otherwise arising under this agreement. Please read this section carefully; it limits our obligations to you.</span></p>
      <p><span>You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from</span></p>
      <ul>
        <li><span>the use, disclosure, or display of your User-Generated Content;</span></li>
        <li><span>your use or inability to use the Service;</span></li>
        <li><span>any modification, price change, suspension or discontinuance of the Service;</span></li>
        <li><span>the Service generally or the software or systems that make the Service available;</span></li>
        <li><span>unauthorized access to or alterations of your transmissions or data;</span></li>
        <li><span>statements or conduct of any third party on the Service;</span></li>
        <li><span>any other user interactions that you input or receive through your use of the Service; or</span></li>
        <li><span>any other matter relating to the Service.</span></li>
      </ul>
      <p><span>Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.</span></p>


      <h3><span>N. Release and Indemnification</span></h3>
      <p><span>Short version: You are responsible for your use of the service. If you harm someone else or get into a dispute with someone else, we will not be involved.</span></p>
      <p><span>If you have a dispute with one or more Users, you agree to release Khyros from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</span></p>
      <p><span>You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement, provided that Khyros (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases Khyros of all liability); and (3) provides to you all reasonable assistance, at your expense.</span></p>


      <h3><span>O. Changes to These Terms</span></h3>
      <p><span>Short version: We want our users to be informed of important changes to our terms, but some changes aren't that important — we don't want to bother you every time we fix a typo. So while we may modify this agreement at any time, we will notify users of any changes that affect your rights and give you time to adjust to them.</span></p>
      <p><span>We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users of material changes to this Agreement, such as price changes, at least 30 days prior to the change taking effect by posting a notice on our Website. For non-material modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service. </span></p>
      <p><span>We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.</span></p>


      <h3><span>P. Miscellaneous</span></h3>
      <h4><span>1. Governing Law</span></h4>
      <p><span>Except to the extent applicable law provides otherwise, this Agreement between you and Khyros and any access to or use of the Website or the Service are governed by the federal laws of the United States of America and the laws of the State of California, without regard to conflict of law provisions. You and Khyros agree to submit to the exclusive jurisdiction and venue of the courts located in the City and County of San Francisco, California.</span></p>

      <h4><span>2. Non-Assignability</span></h4>
      <p><span>Khyros may assign or delegate these Terms of Service and/or the Khyros Privacy Statement, in whole or in part, to any person or entity at any time with or without your consent, including the license grant in Section D.4. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Statement without our prior written consent, and any unauthorized assignment and delegation by you is void.</span></p>

      <h4><span>3. Section Headings and Summaries</span></h4>
      <p><span>Throughout this Agreement, each section includes titles and brief summaries of the following terms and conditions. These section titles and brief summaries are not legally binding.</span></p>

      <h4><span>4. Severability, No Waiver, and Survival</span></h4>
      <p><span>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of Khyros to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.</span></p>

      <h4><span>5. Amendments; Complete Agreement</span></h4>
      <p><span>This Agreement may only be modified by a written amendment signed by an authorized representative of Khyros, or by the posting by Khyros of a revised version in accordance with Section O. Changes to These Terms. These Terms of Service, together with the Khyros Privacy Statement, represent the complete and exclusive statement of the agreement between you and us. This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between you and Khyros relating to the subject matter of these terms including any confidentiality or nondisclosure agreements.</span></p>

      <h4><span>6. Questions</span></h4>
      <p><span>Questions about the Terms of Service? Contact us.</span></p>
    </>
  );
}


const TermsContentHeaderSection = styled.section`
  margin-top:60px;
`;

const TermsContent = () => {
  return (
    <>
    <TermsContentHeaderSection className="section bg-light" id="privacy"> 
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title text-center">
              <h1>Khyros Terms Of Service</h1>
            </div>
          </div>
        </div>
      </div>
    </TermsContentHeaderSection>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <TermsText />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const Terms = () => {
  /*
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // @ts-ignore
      document.getElementById('nav-bar').classList.add('nav-sticky');
    }
    else {
      // @ts-ignore
      document.getElementById('nav-bar').classList.remove('nav-sticky');
    }
  }
  */

  useEffect(() => {
    // @ts-ignore
    // document.body.classList = "";
    // window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  return (
    <React.Fragment>
      <HeaderLegal noScrollSpy={false} light={true} />
      <TermsContent />
      <Footer />
    </React.Fragment >
  );

}

export default Terms;