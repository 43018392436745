import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Layouts
import HeaderLegal from './Layouts/header-legal';
import Footer from './Layouts/footer';

// Modal Video 
// import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import '../css/pe-icon-7.css';
import '../css/materialdesignicons.min.css';

import 'react-image-lightbox/style.css';

const PrivacyText = () => {
  return (
    <>
      <p><span>Effective date: August 3, 2020</span></p>
      <p><span>Thanks for entrusting Khyros Inc. (&ldquo;Khyros&rdquo;, &ldquo;we&rdquo;) with your data, your analysis, and your personal information. Holding on to your private information is a serious responsibility, and we want you to know how we&#39;re handling it.</span></p>
      <p><span>All capitalized terms have their definition in Khyros's </span><span><Link to="/terms" target="_top">Terms of Service</Link></span><span>, unless otherwise noted here.</span></p>
      <h3><span>The Short Version</span></h3>
      <p><span>As described below: We use your personal information as this Privacy Statement describes. No matter where you are, where you live, or what your citizenship is, we provide a high standard of privacy protection to all our users around the world, regardless of their country of origin or location.</span></p>
      <p><span>Of course, the short version and the Summary below don&#39;t tell you everything, so please read on for more details.</span></p>
      <h3><span>Summary</span></h3>
      <table>
          <tbody>
          <tr>
              <td>
                  <p><span>Section</span></p>
              </td>
              <td>
                  <p><span>What can you find there?</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>What information Khyros collects</span></p>
              </td>
              <td>
                  <p><span>Khyros collects information directly from you for your registration, payment, transactions, and user profile. We also automatically collect from you your usage information, cookies and similar technologies, and device information, subject, where necessary, to your consent. Khyros may also collect User Personal Information from third parties. We only collect the minimum amount of personal information necessary from you, unless you choose to provide more.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>What information </span><span>Khyros</span><span>&nbsp;does </span><span>not</span><span>&nbsp;collect</span></p>
              </td>
              <td>
                  <p><span>We don&rsquo;t knowingly collect information from children under 13, and we don&rsquo;t collect </span><span><a href="https://gdpr-info.eu/art-9-gdpr/">Sensitive Personal Information</a></span><span>.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>How Khyros uses your information</span></p>
              </td>
              <td>
                  <p><span>In this section, we describe the ways in which we use your information, including to provide you the Service, to communicate with you, for security purposes, and to improve our Service. We also describe the legal basis upon which we process your information, where legally required.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>How we share the information we collect</span></p>
              </td>
              <td>
                  <p><span>We may share your information with third parties under one of the following circumstances: with your consent, with our service providers, for security purposes, to comply with our legal obligations, or when there is a change of control or sale of corporate entities or business units. We do not sell your personal information and we do not host advertising on Khyros. You can see a list of the service providers that access your information.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Other important information</span></p>
              </td>
              <td>
                  <p><span>We provide additional information specific to public information, and Organizations on Khyros.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Additional services</span></p>
              </td>
              <td>
                  <p><span>We provide information about additional service offerings, including third-party applications, Khyros Pages, and Khyros applications.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>How you can access and control the information we collect</span></p>
              </td>
              <td>
                  <p><span>We provide ways for you to access, alter, or delete your personal information.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Our use of cookies and tracking</span></p>
              </td>
              <td>
                  <p><span>We use cookies for the overall functionality of our Website, and we use a small number of tracking and analytics services on a few parts of our site. We offer a page that makes this very transparent. Please see this section for more information.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>How Khyros secures your information</span></p>
              </td>
              <td>
                  <p><span>We take all measures reasonably necessary to protect the confidentiality, integrity, and availability of your personal information on Khyros and to protect the resilience of our servers.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Khyros&#39;s global privacy practices</span></p>
              </td>
              <td>
                  <p><span>We provide a high standard of privacy protection to all our users around the world.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>How we communicate with you</span></p>
              </td>
              <td>
                  <p><span>We communicate with you by email. You can control the way we contact you in your account settings, or by contacting us.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Resolving complaints</span></p>
              </td>
              <td>
                  <p><span>In the unlikely event that we are unable to resolve a privacy concern quickly and thoroughly, we provide a path of dispute resolution through external arbiters.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Changes to our Privacy Statement</span></p>
              </td>
              <td>
                  <p><span>We notify you of material changes to this Privacy Statement 30 days before any such changes become effective. You may also track changes in our Site Policy repository.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>License</span></p>
              </td>
              <td>
                  <p><span>This Privacy Statement is licensed under the </span><span><a href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero license</a></span><span>.</span></p>
              </td>
          </tr>
          <tr>
              <td>
                  <p><span>Contacting Khyros</span></p>
              </td>
              <td>
                  <p><span>Please feel free to contact us if you have questions about our Privacy Statement.</span></p>
              </td>
          </tr>
          </tbody>
      </table>
      <h2><span>Khyros Privacy Statement</span></h2>

      <h3><span>What Information Khyros</span><span>&nbsp;Collects</span></h3>
      <p><span>&quot;User Personal Information&quot; is any information about one of our Users which could, alone or together with other information, personally identify them or otherwise be reasonably linked or connected with them. Information such as a username and password, an email address, a real name, an Internet protocol (IP) address, and a photograph are examples of &ldquo;User Personal Information.&rdquo;</span></p>
      <p><span>User Personal Information does not include aggregated, non-personally identifying information that does not identify a User or cannot otherwise be reasonably linked or connected with them. We may use such aggregated, non-personally identifying information for research purposes and to operate, analyze, improve, and optimize our Website and Service.</span></p>
      
      <h4><span>Information Users Provide Directly to Khyros</span></h4>
      
      <h4><span>Registration Information</span></h4>
      <p><span>We require some basic information at the time of account creation. When you create your own username and password, we ask you for a valid email address.</span></p>
      
      <h4><span>Payment Information</span></h4>
      <p><span>If you sign on to a paid Account with us, we collect your full name, address, and credit card information or PayPal information. Please note, Khyros does not process or store your credit card information or PayPal information, but our third-party payment processor does.</span></p>
      
      <h4><span>Profile Information</span></h4>
      <p><span>You may choose to give us more information for your Account profile, such as your full name, an avatar which may include a photograph, your biography, your location, your company, and a URL to a third-party website. This information may include User Personal Information. Please note that your profile information may be visible to other Users of our Service.</span></p>
      

      <h3><span>Information Khyros Automatically Collects from Your Use of the Service</span></h3>

      <h4><span>Transactional Information</span></h4>
      <p><span>If you have a paid Account with us, we automatically collect certain information about your transactions on the Service, such as the date, time, and amount charged.</span></p>
      
      <h4><span>Usage Information</span></h4>
      <p><span>If you&#39;re accessing our Service or Website, we automatically collect the same basic information that most services collect, subject, where necessary, to your consent. This includes information about how you use the Service, such as the pages you view, the referring site, your IP address and session information, and the date and time of each request. This is information we collect from every visitor to the Website, whether they have an Account or not. This information may include User Personal information.</span></p>
      
      <h4><span>Cookies and Similar Technologies Information</span></h4>
      <p><span>As further described below, and subject, where applicable, to your consent, we automatically collect information from cookies and similar technologies (such as cookie ID and settings) to keep you logged in, to remember your preferences, and to identify you and your device.</span></p>
      
      <h4><span>Device Information</span></h4>
      <p><span>We may collect certain information about your device, such as its IP address, browser or client application information, language preference, operating system and application version, device type and ID, and device model and manufacturer. This information may include User Personal information.</span></p>
      
      <h4><span>Information We Collect from Third Parties</span></h4>
      <p><span>Khyros may collect User Personal Information from third parties. For example, this may happen if you sign up for training or to receive information about Khyros from one of our vendors, partners, or affiliates. Khyros does not purchase User Personal Information from third-party data brokers.</span></p>
      
      
      <h3><span>What Information Khyros Does Not Collect</span></h3>
      <p><span>We do not intentionally collect &ldquo;</span><span><a href="https://gdpr-info.eu/art-9-gdpr/">Sensitive Personal Information</a></span><span>&rdquo;, such as personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person&rsquo;s sex life or sexual orientation. If you choose to store any Sensitive Personal Information on our servers, you are responsible for complying with any regulatory controls regarding that data.</span></p>
      <p><span>If you are a child under the age of 13, you may not have an Account on Khyros. Khyros does not knowingly collect information from or direct any of our content specifically to children under 13. If we learn or have reason to suspect that you are a User who is under the age of 13, we will have to close your Account. Please see our </span><span><Link to="/terms" target="_top">Terms of Service</Link></span><span> for information about Account termination. Different countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not have an Account on Khyros.</span></p>
      <p><span>We do not intentionally collect User Personal Information that is stored in your repositories or other free-form content inputs. Any personal information within a user&#39;s account is the responsibility of the account owner.</span></p>
      
      
      <h3><span>How Khyros Uses Your Information</span></h3>
      <ul>
          <li><span>We may use your information for the following purposes:</span></li>
          <li><span>We use your Registration Information to create your account, and to provide you the Service.</span></li>
          <li><span>We use your Payment Information to provide you with the Paid Account service, or any other Khyros paid service you request.</span></li>
          <li><span>We use your User Personal Information, specifically your username, to identify you on Khyros.</span></li>
          <li><span>We use your Profile Information to fill out your Account profile and to share that profile with other users if you ask us to.</span></li>
          <li><span>We use your email address to communicate with you, if you&#39;ve said that&#39;s okay, and only for the reasons you&rsquo;ve said that&rsquo;s okay. </span></li>
          <li><span>We use User Personal Information to respond to support requests.</span></li>
          <li><span>We may use User Personal Information to invite you to take part in surveys, beta programs, or other research projects, subject, where necessary, to your consent .</span></li>
          <li><span>We use Usage Information and Device Information to better understand how our Users use Khyros and to improve our Website and Service.</span></li>
          <li><span>We may use your User Personal Information if it is necessary for security purposes or to investigate possible fraud or attempts to harm Khyros or our Users.</span></li>
          <li><span>We may use your User Personal Information to comply with our legal obligations, protect our intellectual property, and enforce our </span><span><Link to="/terms" target="_top">Terms of Service</Link></span><span>.</span></li>
          <li><span>We limit our use of your User Personal Information to the purposes listed in this Privacy Statement. If we need to use your User Personal Information for other purposes, we will ask your permission first. You can always see what information we have, how we&#39;re using it, and what permissions you have given us in your user profile.</span></li>
      </ul>
      
      <h4><span>Our Legal Bases for Processing Information</span></h4>
      <p><span>To the extent that our processing of your User Personal Information is subject to certain international laws (including, but not limited to, the European Union&#39;s General Data Protection Regulation (GDPR)), Khyros is required to notify you about the legal basis on which we process User Personal Information. Khyros processes User Personal Information on the following legal bases:</span></p>
      <ul>
          <li><span>Contract Performance:</span></li>
      </ul>
      <ul>
          <li><span>When you create a Khyros Account, you provide your Registration Information. We require this information for you to enter into the Terms of Service agreement with us, and we process that information on the basis of performing that contract. We also process your username and email address on other legal bases, as described below.</span></li>
          <li><span>If you have a paid Account with us, we collect and process additional Payment Information on the basis of performing that contract.</span></li>
      </ul>
      <ul>
          <li><span>Consent:</span></li>
      </ul>
      <ul>
          <li><span>We rely on your consent to use your User Personal Information under the following circumstances: when you fill out the information in your user profile; when you decide to participate in a Khyros training, research project, beta program, or survey; and for marketing purposes, where applicable. All of this User Personal Information is entirely optional, and you have the ability to access, modify, and delete it at any time. While you are not able to delete your email address entirely, you can make it private. You may withdraw your consent at any time.</span></li>
      </ul>
      <ul>
          <li><span>Legitimate Interests:</span></li>
      </ul>
      <ul>
          <li><span>Generally, the remainder of the processing of User Personal Information we perform is necessary for the purposes of our legitimate interest, for example, for legal compliance purposes, security purposes, or to maintain ongoing confidentiality, integrity, availability, and resilience of Khyros&rsquo;s systems, Website, and Service.</span></li>
      </ul>
      <ul>
          <li><span>If you would like to request deletion of data we process on the basis of consent or if you object to our processing of personal information, please contact Khyros.</span></li>
      </ul>


      <h3><span>How We Share the Information We Collect</span></h3>
      <p><span>We may share your User Personal Information with third parties under one of the following circumstances:</span></p>
      
      <h4><span>With Your Consent</span></h4>
      <p><span>We share your User Personal Information, if you consent, after letting you know what information will be shared, with whom, and why. </span></p>
      
      <h4><span>With Service Providers</span></h4>
      <p><span>We share User Personal Information with a limited number of service providers who process it on our behalf to provide or improve our Service, and who have agreed to privacy restrictions similar to the ones in our Privacy Statement by signing data protection agreements or making similar commitments. Our service providers perform payment processing, customer support ticketing, network data transmission, security, and other similar services. While Khyros processes all User Personal Information in the United States, our service providers may process data outside of the United States or the European Union.</span></p>
      
      <h4><span>For Security Purposes</span></h4>
      <p><span>If you are a member of an Organization, Khyros may share your username, usage information, and device information associated with that Organization with an owner and/or administrator of the Organization who has agreed to the Corporate Terms of Service or applicable customer agreements, to the extent that such information is provided only to investigate or respond to a security incident that affects or compromises the security of that particular Organization.</span></p>
      
      <h4><span>For Legal Disclosure</span></h4>
      <p><span>Khyros strives for transparency in complying with legal process and legal obligations. Unless prevented from doing so by law or court order, or in rare, exigent circumstances, we make a reasonable effort to notify users of any legally compelled or required disclosure of their information. Khyros may disclose User Personal Information or other information we collect about you to law enforcement if required in response to a valid subpoena, court order, search warrant, a similar government order, or when we believe in good faith that disclosure is necessary to comply with our legal obligations, to protect our property or rights, or those of third parties or the public at large.</span></p>
      <p><span>For more information about our disclosure in response to legal requests, see our </span><span><Link to="/requests" target="_top">Guidelines for Legal Requests of User Data</Link></span><span>.</span></p>
      
      <h4><span>Change in Control or Sale</span></h4>
      <p><span>We may share User Personal Information if we are involved in a merger, sale, or acquisition of corporate entities or business units. If any such change of ownership happens, we will ensure that it is under terms that preserve the confidentiality of User Personal Information, and we will notify you on our Website or by email before any transfer of your User Personal Information. The organization receiving any User Personal Information will have to honor any promises we made in our Privacy Statement or </span><span><Link to="/terms" target="_top">Terms of Service</Link></span><span>.</span></p>
      
      <h4><span>Aggregate, Non-personally Identifying Information</span></h4>
      <p><span>We share certain aggregated, non-personally identifying information with others about how our users, collectively, use Khyros, or how our users respond to our other offerings, such as our conferences or events.</span></p>
      <p><span>We do not sell your User Personal Information for monetary or other consideration.</span></p>
      <p><span>Please note: The California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;) requires businesses to state in their privacy policy whether or not they disclose personal information in exchange for monetary or other valuable consideration. While CCPA only covers California residents, when it goes into effect we will voluntarily extend its core rights for people to control their data to </span><span>all</span><span>&nbsp;of our users in the United States, not just those who live in California.</span></p>
      
      <h4><span>Data Retention and Deletion of Data</span></h4>
      <p><span>Generally, Khyros retains User Personal Information for as long as your account is active or as needed to provide you services.</span></p>
      <p><span>If you would like to cancel your account or delete your User Personal Information, you may do so in your user profile. We retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile (within reason) within 90 days of your request. You may contact Khyros to request the erasure of the data we process on the basis of consent within 30 days.</span></p>
      
      
      <h3><span>Our Use of Cookies and Tracking</span></h3>
      
      <h4><span>Cookies</span></h4>
      <p><span>Khyros uses cookies to make interactions with our service easy and meaningful. Cookies are small text files that websites often store on computer hard drives or mobile devices of visitors. We use cookies (and similar technologies, like HTML5 localStorage) to keep you logged in, remember your preferences, and provide information for future development of Khyros. For security purposes, we use cookies to identify a device. By using our Website, you agree that we can place these types of cookies on your computer or device. If you disable your browser or device&rsquo;s ability to accept these cookies, you will not be able to log in or use Khyros&rsquo;s services.</span></p>
      
      
      <h4><span>Tracking and Analytics</span></h4>
      <p><span>We use a number of third-party analytics and service providers to help us evaluate our Users&#39; use of Khyros, compile statistical reports on activity, and improve our content and Website performance. We only use these third-party analytics providers on certain areas of our Website, and all of them have signed data protection agreements with us that limit the type of User Personal Information they can collect and the purpose for which they can process the information. In addition, we use our own internal analytics software to provide features and improve our content and performance.</span></p>
      
      <h3><span>How Khyros Secures Your Information</span></h3>
      <p><span>Khyros takes all measures reasonably necessary to protect User Personal Information from unauthorized access, alteration, or destruction; maintain data accuracy; and help ensure the appropriate use of User Personal Information.</span></p>
      <p><span>Khyros enforces a written security information program. Our program:</span></p>
      <ul>
          <li><span>aligns with industry recognized frameworks;</span></li>
          <li><span>includes security safeguards reasonably designed to protect the confidentiality, integrity, availability, and resilience of our Users&#39; data;</span></li>
          <li><span>is appropriate to the nature, size, and complexity of Khyros&rsquo;s business operations;</span></li>
          <li><span>includes incident response and data breach notification processes; and</span></li>
          <li><span>complies with applicable information security-related laws and regulations in the geographic regions where Khyros does business.</span></li>
      </ul>
      <p><span>In the event of a data breach that affects your User Personal Information, we will act promptly to mitigate the impact of a breach and notify any affected Users without undue delay.</span></p>
      <p><span>Transmission of data on Khyros is encrypted using SSH and HTTPS (TLS). We manage our own cages and racks at top-tier data centers with a high level of physical and network security, and when data is stored with a third-party storage provider, it is encrypted.</span></p>
      <p><span>No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security. </span></p>
      
      
      <h3><span>Khyros</span><span>&#39;s Global Privacy Practices</span></h3>
      <p><span>We store and process the information that we collect in the United States in accordance with this Privacy Statement though our service providers may store and process data outside the United States. However, we understand that we have Users from different countries and regions with different privacy expectations, and we try to meet those needs even when the United States does not have the same privacy framework as other countries.</span></p>
      <p><span>We provide a high standard of privacy protection&mdash;as described in this Privacy Statement&mdash;to all our users around the world, regardless of their country of origin or location, and we are proud of the levels of notice, choice, accountability, security, data integrity, access, and recourse we provide. We work hard to comply with the applicable data privacy laws wherever we do business. Additionally, if our vendors or affiliates have access to User Personal Information, they must sign agreements that require them to comply with our privacy policies and with applicable data privacy laws.</span></p>
      <p><span>In particular:</span></p>
      <ul>
          <li><span>Khyros provides clear methods of unambiguous, informed, specific, and freely given consent at the time of data collection, when we collect your User Personal Information using consent as a basis.</span></li>
          <li><span>We collect only the minimum amount of User Personal Information necessary for our purposes, unless you choose to provide more. We encourage you to only give us the amount of data you are comfortable sharing.</span></li>
          <li><span>We offer you simple methods of accessing, altering, or deleting the User Personal Information we have collected, where legally permitted.</span></li>
          <li><span>We provide our Users notice, choice, accountability, security, and access regarding their User Personal Information, and we limit the purpose for processing it. We also provide our Users a method of recourse and enforcement. These are the Privacy Shield Principles, but they are also just good practices.</span></li>
      </ul>
      
      
      <h3><span>How We Communicate with You</span></h3>
      <p><span>We use your email address to communicate with you, if you&#39;ve said that&#39;s okay, and only for the reasons you&rsquo;ve said that&rsquo;s okay. For example, if you contact our Support team with a request, we respond to you via email. You may manage your communication preferences in your user profile.</span></p>
      <p><span>Depending on your email settings, Khyros may occasionally send notification emails about changes in your account, new features, requests for feedback, important policy changes, or to offer customer support. We also send marketing emails, based on your choices and in accordance with applicable laws and regulations. </span></p>
      <p><span>Our emails may contain a pixel tag, which is a small, clear image that can tell us whether or not you have opened an email and what your IP address is. We use this pixel tag to make our email more effective for you and to make sure we&rsquo;re not sending you unwanted email.</span></p>
      
      
      <h3><span>Resolving Complaints</span></h3>
      <p><span>If you have concerns about the way Khyros is handling your User Personal Information, please let us know immediately. We want to help. You may contact us directly with the subject line &quot;Privacy Concerns.&quot; We will respond promptly &mdash; within 45 days at the latest.</span></p>
      
      <h4><span>Dispute Resolution Process</span></h4>
      <p><span>In the unlikely event that a dispute arises between you and Khyros regarding our handling of your User Personal Information, we will do our best to resolve it. If we cannot, we have selected to cooperate with the relevant EU Data Protection Authority, or a panel established by the European data protection authorities, for resolving disputes with EU individuals, and with the Swiss Federal Data Protection and Information Commissioner (FDPIC) for resolving disputes with Swiss individuals. Please contact us if you&rsquo;d like us to direct you to your data protection authority contacts.</span></p>
      <p><span>Additionally, if you are a resident of an EU member state, you have the right to file a complaint with your local supervisory authority.</span></p>
      
      <h4><span>Independent Arbitration</span></h4>
      <p><span>Under certain limited circumstances, EU, European Economic Area (EEA), Swiss, and UK individuals may invoke binding Privacy Shield arbitration as a last resort if all other forms of dispute resolution have been unsuccessful. To learn more about this method of resolution and its availability to you, please read more about </span><span><a href="https://www.privacyshield.gov/article?id%3DANNEX-I-introduction">Privacy Shield</a></span><span>. Arbitration is not mandatory; it is a tool you can use if you so choose.</span></p>
      <p><span>We are subject to the jurisdiction of the U.S. Federal Trade Commission (FTC).</span></p>
      
      
      <h3><span>Changes to our Privacy Statement</span></h3>
      <p><span>Although most changes are likely to be minor, Khyros may change our Privacy Statement from time to time. We will provide notification to Users of material changes to this Privacy Statement through our Website at least 30 days prior to the change taking effect by posting a notice on our home page or sending email to the primary email address specified in your Khyros account. For changes to this Privacy Statement that are not material changes or that do not affect your rights, we encourage Users to check our Site Policy repository frequently.</span></p>
      
      
      <h3><span>License</span></h3>
      <p><span>This Privacy Statement is licensed under this </span><span><a href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero license</a></span><span>. </span></p>
      
      
      <h3><span>Contacting Khyros</span></h3>
      <p><span>Questions regarding Khyros&#39;s Privacy Statement or information practices should be directed to our contact form.</span></p>
      <p><span></span></p>
    </>
  );
}


const PrivacyContentHeaderSection = styled.section`
  margin-top:60px;
`;

const PrivacyContent = () => {
  return (
    <>
    <PrivacyContentHeaderSection className="section bg-light" id="privacy"> 
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title text-center">
              <h1>Khyros Privacy Statement</h1>
            </div>
          </div>
        </div>
      </div>
    </PrivacyContentHeaderSection>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <PrivacyText />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const Privacy = () => {

  /*
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // @ts-ignore
      document.getElementById('nav-bar').classList.add('nav-sticky');
    }
    else {
      // @ts-ignore
      document.getElementById('nav-bar').classList.remove('nav-sticky');
    }
  }
  */

  useEffect(() => {
    // @ts-ignore
    // document.body.classList = "";
    // window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  return (
    <React.Fragment>
      <HeaderLegal noScrollSpy={false} light={true} />
      <PrivacyContent />
      <Footer />
    </React.Fragment >
  );

}

export default Privacy;


