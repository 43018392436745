import React, { Component } from 'react';

class Services extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="services">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="title text-center mb-5">
                                    {/* <p className="text-uppercase text-muted mb-2 f-13 subtitle">Khyros Edge</p> */}
                                    <h1>Applications</h1>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="services-box p-4">
                                    <div className="services-icon mb-3"> 
                                        <i className="mdi mdi-drone h1 text-custom"></i>
                                    </div>
                                    <div className="services-desc">
                                        <h5 className="mb-3 f-18 applications-headers-size">Military &amp; intelligence</h5>
                                        <p className="text-muted">Veteran founded, Khyros has already proven itself in the toughest military environments.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-box p-4">
                                    <div className="services-icon mb-3">
                                        <i className="mdi mdi-biohazard h1 text-custom"></i> 
                                    </div>
                                    <div className="services-desc">
                                        <h5 className="mb-3 f-18 applications-headers-size">Public health &amp; safety</h5>
                                        <p className="text-muted">Combine datasets to identify hotspots before they spiral out of control.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-box p-4">
                                    <div className="services-icon mb-3">
                                        <i className="mdi mdi-security h1 text-custom"></i>
                                    </div>
                                    <div className="services-desc">
                                        <h5 className="mb-3 f-18 applications-headers-size">Law enforcement</h5>
                                        <p className="text-muted">Identify patterns in crime to forecast where future events are likely to occur.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {/* <div className="col-lg-4">
                                <div className="services-box p-4">
                                    <div className="services-icon mb-3">
                                        <i className="mdi mdi-account-convert h1 text-custom"></i>
                                    </div>
                                    <div className="services-desc">
                                        <h5 className="mb-3 f-18">Work Faster</h5> 
                                        <p className="text-muted">AI powered data analysis and event anticipation means less manual data entry for everyone.</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-4">
                                <div className="services-box p-4">
                                    <div className="services-icon mb-3">
                                        <i className="mdi mdi-verified h1 text-custom"></i>
                                    </div>
                                    <div className="services-desc">
                                        <h5 className="mb-3 f-18 applications-headers-size">Fraud detection</h5>
                                        <p className="text-muted">Instantly detect events that are out of pattern after establishing normal behavior.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-box p-4">
                                    <div className="services-icon mb-3">
                                        <i className="mdi mdi-cellphone h1 text-custom"></i>
                                    </div>
                                    <div className="services-desc">
                                        <h5 className="mb-3 f-18 applications-headers-size">Ad tech analysis</h5>
                                        <p className="text-muted">Know what motivates your customer in big ad tech data with a proven technology.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}

export default Services;


