import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Cta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            freeTrialModal: false,
        };
        this.toggleFreeTrialModal = this.toggleFreeTrialModal.bind(this);
    }

    toggleFreeTrialModal() {
        this.setState(prevState => ({
            freeTrialModal: !prevState.freeTrialModal
        }));
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.freeTrialModal} toggle={this.toggleFreeTrialModal} className={this.props.className}>
                    <ModalHeader toggle={this.toggleModtoggleFreeTrialModalal}>
                        <h5 id="exampleModalLongTitle">Free trial</h5>
                    </ModalHeader>
                    <ModalBody className="p-4">
                        <div className="custom-form">
                            <div id="message"></div>
                            <form method="post" action="https://formspree.io/mnqgoepp" name="contact-form" id="contact-form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mb-4">
                                            <input name="name" id="name" type="text" className="form-control" placeholder="Name" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input name="_replyto" id="email" type="email" className="form-control" placeholder="Email" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input name="organization" id="organization" type="text" className="form-control" placeholder="Organization" />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" id="message" rows="4" className="form-control" placeholder="Tell us about your use case"></textarea>
                                        </div>
                                        <p>
                                            Send us a message and we will be in touch.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 text-right">
                                        <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom" value="Request Trial" />
                                        <div id="simple-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
                <section className="section bg-custom">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="cta-content text-center text-white">
                                    <h3 className="mb-4">Free trial</h3>
                                    <p className="text-light f-16">Sign up for a free trial of Khyros to experience what AI can tell you about your geospatial data.</p>
                                    <div className="pt-3">
                                        <Link to="#" onClick={this.toggleFreeTrialModal} className="btn btn-custom-white">FREE TRIAL <i className="mdi mdi-arrow-right"></i> </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}

export default Cta;


