import React, { Component } from 'react';
import khyros_ui from '../../../images/features/ui_image.png';
import khyros_compare_clusters from '../../../images/features/compare_1.png';
import khyros_unstovepipe from '../../../images/features/unstovepipe.png';
import khyros_find from '../../../images/features/find_1.png';
// import khyros_ecosystem from '../../../images/features/khyros_ecosystem.png';

class Features extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="features">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <div className="title text-center">
                                    {/* <p className="text-uppercase text-muted mb-2 f-13 subtitle">Unsurpassed geotemporal data insights</p> */}
                                    <h1>Features</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row vertical-content mt-5">
                            <div className="col-lg-6">
                                <div className="features-img">
                                    <img src={khyros_ui} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="feautures-content p-5">
                                    <div className="feautures-icon mb-4">
                                        <i className="mdi mdi-clock-fast h4 text-custom"></i>
                                    </div>
                                    <div>
                                        <h5 className="mb-3 feature-headers-size">Visualize in seconds</h5>
                                        <p className="text-muted feature-paragraphs-size">Drag and drop your data, and let AI take care of the rest.</p>
                                        <div>
                                        <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Import many file types</p>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Intuitive user interface</p>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Flexible AI driven search</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row vertical-content">
                            <div className="col-lg-6">
                                <div className="feautures-content p-5">
                                    <div className="feautures-icon mb-4">
                                        <i className="mdi mdi-alert-decagram h4 text-custom"></i>
                                    </div>
                                    <div>
                                        <h5 className="mb-3 feature-headers-size">Automated forecasting</h5>
                                        <p className="text-muted feature-paragraphs-size">Patterns most likely to reoccur are displayed first.</p>
                                        <div>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Alert users to overlapping patterns</p>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Highlight colocation-cotime events</p>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Instantly flags out of character activity</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="features-img">
                                    <img src={khyros_compare_clusters} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                        <div className="row vertical-content mt-5">
                            <div className="col-lg-6">
                                <div className="features-img">
                                    <img src={khyros_unstovepipe} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="feautures-content p-5">
                                    <div className="feautures-icon mb-4">
                                        <i className="mdi mdi-export h4 text-custom"></i>
                                    </div>
                                    <div>
                                        <h5 className="mb-3 feature-headers-size">Free your data</h5>
                                        <p className="text-muted feature-paragraphs-size">Data and analytical outputs go where you need them.</p>
                                        <div>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Custom data adapters</p>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Export to open data formats</p>
                                            <p className="text-muted feature-paragraphs-size"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>APIs for enterprise scale analysis</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row vertical-content">
                            <div className="col-lg-6">
                                <div className="feautures-content p-5">
                                    <div className="feautures-icon mb-4">
                                        <i className="mdi mdi-shield-half-full h4 text-custom"></i>
                                    </div>
                                    <div>
                                        <h5 className="mb-3">Have Confidence</h5>
                                        <p className="text-muted">Khyros utilizes cutting edge design principles and industry best practices to ensure it is always running and it can adapt to meet the shifting needs of its users.</p>
                                        <div>
                                            <p className="text-muted"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>A DevOps pipeline to meet changes at the speed of mission</p>
                                            <p className="text-muted"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>An aerospace systems like testing harness for 100% reliability</p>
                                            <p className="text-muted"><i className="mdi mdi-checkbox-marked-outline h5 text-custom mr-2"></i>Automated systems recovery to handle any situation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="features-img">
                                    <img src={khyros_ecosystem} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </React.Fragment >
        );
    }
}

export default Features;


