import React, { Component } from 'react';

// Images
import khyros_logo_black from "../../../images/agency-about/khyros_logo_black.png";
import forecast_image from "../../../images/agency-about/forecast.png";
import compare_image from "../../../images/agency-about/compare.png";
import anomoly_image from "../../../images/agency-about/anomaly.png";

import Typewriter from 'typewriter-effect';



class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>        
               <section className="section" id="about">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-11">
                        <h2 className="text-center typewriter-line">
                            <span className="mr-1">Empower your</span><span>
                            <Typewriter options={{
                                strings: ['analysts', 'officers', 'systems', 'enterprise'],
                                autoStart: true,
                                loop: true,
                            }}/>
                            </span>
                        </h2>
                        { /* <h2 className="text-center">Forecast Geospatial Activity</h2> */ }
                        {
                            /*
                                <div className="about-content text-center">
                                    <div className="typewriter-line">
                                        <span>Reduce the geospatial decision making loop to seconds for your&nbsp;</span>
                                        <span><Typewriter options={{
                                                    strings: ['analysts.', 'officers.', 'sensors.', 'enterprise.'],
                                                    autoStart: true,
                                                    loop: true,
                                                }}/></span>
                                    </div>
                                </div>
                            */
                        }

                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-4">
                        <div className="about-box text-center p-4">
                            <div className="about-icon mb-3">
                                <img src={forecast_image} alt="" className="testi-img avatar-md img-fluid rounded-circle mx-auto d-block" />
                                {/* <i className="pe-7s-monitor text-custom h1"></i> */}
                            </div>
                            <div className="about-desc">
                                <h5 className="mb-3 f-18 about-headers-size">Forecast events</h5>
                                <p className="text-muted about-paragraphs-size">Automatically identify patterns. Forecast future events by timeframe.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="about-box text-center p-4">
                            <div className="about-icon mb-3">
                                <img src={compare_image} alt="" className="testi-img avatar-md img-fluid rounded-circle mx-auto d-block" />
                                {/* <i className="pe-7s-sharetext-custom h1"></i> */}
                            </div>
                            <div className="about-desc">
                                <h5 className="mb-3 f-18 about-headers-size">Find and compare</h5>
                                <p className="text-muted about-paragraphs-size">Combine and analyze disparate data for easy comparative analysis.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="about-box text-center p-4">
                            <div className="about-icon mb-3">
                            <img src={anomoly_image} alt="" className="testi-img avatar-md img-fluid rounded-circle mx-auto d-block" />
                                {/* <i className="pe-7s-server text-custom h1"></i> */}
                            </div>
                            <div className="about-desc">
                                <h5 className="mb-3 f-18 about-headers-size">Identify anomalies</h5>
                                <p className="text-muted about-paragraphs-size">Use geospatial patterns to instantly flag suspicious or irregular activity.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </React.Fragment >
        );
    }
}

export default AboutUs;


